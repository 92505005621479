<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-link="headerLink"
      :header-size="headerSize"
      :subheader="subheader"
      :bodytext="bodytext"
      :theme-color="theme"
      :button="button"
      :label="label"
      :checkbox-calendar="checkboxCalendar"
      :checkbox-color="checkboxColor"
      :checkbox-logo="checkboxLogo"
      :google-tag="googleTag"
      :lang="currentLocale"
      class="t3-ce-text"
      @gtm-event="gtmEvent"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useNuxtApp } from '#app'
import { useButton, useCurrentLocale, useLabel } from '#imports'

import type { T3CeTextProps } from '~/types'

const props = withDefaults(defineProps<T3CeTextProps>(), {
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  bodytext: '',
  theme: 'default',
  buttonTitle: '',
  buttonColor: 'solid-default',
  buttonSize: 'large',
  buttonWrap: false,
  arrowButton: false,
  buttonLink: '',
  checkboxCalendar: false,
  checkboxColor: false,
  checkboxLogo: false,
  googleTag: ''
})

const currentLocale = useCurrentLocale()
const button = useButton(props)
const label = useLabel(props)

const TitleWithHighlights = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeText/TitleWithHighlights')
)
const TwoColumnsText = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeText/TwoColumnsText')
)
const CallToAction = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeText/CallToAction')
)
const TextWithReadMore = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeText/TextWithReadMore')
)
const TitleWithLabel = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeText/TitleWithLabel')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'callToAction':
      return CallToAction
    case 'twoColumnsText':
      return TwoColumnsText
    case 'textWithReadMore':
      return TextWithReadMore
    case 'titleWithLabel':
      return TitleWithLabel
    default:
      return TitleWithHighlights
  }
})

const { callHook } = useNuxtApp()
const gtmEvent = (eventName: unknown) => {
  callHook('gtm', { event: eventName as string })
}
</script>
